import React from "react";
import styled from 'styled-components';

// 固定画像のパスを定義
const minoImage1 = require('../../images/mino/mino-1-1.jpg');
const minoImage2 = require('../../images/mino/mino-1-2.jpg');

// スタイルを定義
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Image = styled.img`
  width: 42%;
  height: auto;
  max-width: 500px;
  box-sizing: border-box;
  padding: 8px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const MinoBody1 = () => {
  return (
    <Container>
      <Title>窯元とのつながりを大切に</Title>
      <p>
        美濃焼の産地である岐阜県土岐市に物流拠点を設け、 <br/>
        現在は100社を超えるご協力メーカーと直接お取引をさせて頂いており、<br/>
        お客様のご要望を地の利を生かした迅速な対応を可能としております。<br/><br/>
        また本社営業も産地に赴き、メーカー様との懇親会を開催し、親交を深めております。<br/>
      </p>
      <ImageContainer>
        <Image src={minoImage1} alt="Mino1-1" />
        <Image src={minoImage2} alt="Mino1-2" />
      </ImageContainer>
    </Container>
  );
};

export default MinoBody1;
