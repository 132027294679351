import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
  table: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  label: {
    fontWeight: '600',
    width: '120px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    verticalAlign: 'top'
  },
  value: {
    width: '600px',
    paddingTop: '8px',
    paddingBottom: '8px'
  },

};

const CompanyInformation = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>会社概要</h1>
      <table style={styles.table}>
        <tbody>
          <tr>
            <td style={styles.label}>会社名</td>
            <td style={styles.value}>株式会社 豊栄<br/>（かぶしきがいしゃ ほうえい）</td>
          </tr>
          <tr>
            <td style={styles.label}>代表者</td>
            <td style={styles.value}>代表取締役 加藤 伸 （かとうしん）</td>
          </tr>
          <tr>
            <td style={styles.label}>本社</td>
            <td style={styles.value}>
              〒169-0074<br />
              東京都新宿区北新宿四丁目13番10
            </td>
          </tr>
          <tr>
            <td style={styles.label}>美濃物流センター</td>
            <td style={styles.value}>
              〒509-5171<br />
              岐阜県土岐市泉北山町1-3<br />
              フリーダイヤル TEL: 0120-05-5472
            </td>
          </tr>
          <tr>
            <td style={styles.label}>設立</td>
            <td style={styles.value}>1998年6月4日</td>
          </tr>
          <tr>
            <td style={styles.label}>資本金</td>
            <td style={styles.value}>15,000,000円</td>
          </tr>
          <tr>
            <td style={styles.label}>取引銀行</td>
            <td style={styles.value}>
              三菱UFJ銀行・三井住友銀行・みずほ銀行・りそな銀行・十六銀行・東農信用金庫
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CompanyInformation;