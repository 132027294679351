import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
};

const CommercialFlow = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>商流</h1>
        <p>
        消費地である東京に営業事務所を持ち、<br/>
        美濃焼の産地である岐阜県土岐市に物流拠点を置くことで、<br/><br/>
          「お客様」<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;↕<br/>
          「豊栄」<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;↕<br/>
          「メーカー」<br/><br/>
          という「お客様」と「メーカー」を最短で結ぶ商流が生まれ、緊密な情報共有、納期・商品開発の迅速化、物流のコストダウンが可能になりました。
        </p>
    </div>
  );
};

export default CommercialFlow;